/* eslint-disable no-nested-ternary */
import React, {
  useEffect, useState, useRef
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  Button, Checkbox, FormControlLabel, Grid, IconButton, Paper, SvgIcon, Table, TableBody, TableCell,
  TableHead, TableRow, TextField, Tooltip, Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PlusCircleIcon from '@material-ui/icons/AddCircle';
import ReorderIcon from '@material-ui/icons/Reorder';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ResetIcon from '@material-ui/icons/Undo';

import ManageFields from 'src/Shared/components/ManageFields/ManageFields';
import EmptyText from 'src/Shared/components/EmptyText/EmptyText';
import { isShowManageFields } from 'src/Shared/utils/helpers';
import {
  amountRelatedFields, lineColumnsReprocess, userRoles
} from 'src/config';
import useStyles from './style';

const DisplayLines = ({
  t,
  ready,
  preferredFields,
  getLinesDisplayFields,
  sendLines,
  handleSendLinesChange,
  onAddLine,
  details,
  adjustLinesOrder,
  getTFLabel,
  glAccounts,
  vatGroups,
  costCenters,
  projects,
  onEditLine,
  onDeleteLine,
  includeLinesInExport,
  handleIncludeLinesInExportChange,
  onUpdateLine,
  parameterCompany,
  onAddLineConfirm,
  textFieldSelectedKey,
  onFocusField,
  supplier,
  setTextFieldSelectedKey,
  reprocessingLines,
  sendLinesForReprocessing,
  isLinesReprocessingBtnDisabled,
  isResetLineBoundariesBtnDisabled,
  resetLineBoundaries,
}) => {
  const dimensionsLists = {
    lineGLAccount: glAccounts,
    lineVatGroup: vatGroups,
    lineCostCenter: costCenters,
    lineProject: projects
  };
  const { user } = useSelector((state) => state.auth);

  const decimalSeparator = user.monetaryDecimalSeparator;
  const amountRegex = new RegExp(`^-?\\d*[${decimalSeparator === '.' ? '.' : `.${decimalSeparator}`}]?\\d+$`);
  const noWhiteSpace = /.*\S.*$/;
  const leadingWhiteSpace = /^\s+.+$/;
  const classes = useStyles();
  const [showManageFields, setShowManageFields] = useState(false);
  const [lineDetails, setLineDetails] = useState(details.lines || []);
  const [supplierUnitMeasurementVals, setSupplierUnitMeasurementVals] = useState(supplier?.inventories?.map((p) => p.unitMeasurement) || []);
  const [unitMeasurementVals, setUnitMeasurementVals] = useState(supplier?.inventories?.map((p) => p.unitMeasurement) || []);

  const tableRefs = {
    table1Ref: useRef(null),
    table2Ref: useRef(null),
    table3Ref: useRef(null),
    table2HeaderRef: useRef(null),
  };

  const handleScroll = (sourceTable, targetTable, targetTable2) => {
    const scrollPositionY = tableRefs[sourceTable].current.scrollTop;
    tableRefs[targetTable].current.scrollTop = scrollPositionY;
    tableRefs[targetTable2].current.scrollTop = scrollPositionY;
  };

  const handleCloseManageFields = () => {
    setShowManageFields(false);
  };

  useEffect(() => setLineDetails(details.lines), [details]);

  useEffect(() => {
    setSupplierUnitMeasurementVals([...new Set(supplier?.inventories?.filter((p) => p.unitMeasurement).map((p) => p.unitMeasurement))] || []);
    setUnitMeasurementVals([...new Set(supplier?.inventories?.filter((p) => p.unitMeasurement).map((p) => p.unitMeasurement))] || []);
  }, [supplier]);

  const handleChange = (value, line, prop) => {
    const val = value;
    if (leadingWhiteSpace.test(val)) {
      val.trimStart();
    }
    const newLineDetails = lineDetails.map((l) => {
      if (l.line === line) {
        return {
          ...l,
          [prop]: val
        };
      }
      return l;
    });
    setLineDetails(newLineDetails);
  };

  const handleOnBlur = (value, line, prop) => {
    let updatedLine;
    const val = value;
    if (leadingWhiteSpace.test(val)) {
      val.trimStart();
    }
    let product;
    if (supplier) {
      const supplierProducts = supplier?.inventories || [];
      if (prop === 'lineArticle' && supplierProducts.some((p) => p.article === val)) {
        product = supplierProducts.find((p) => p.article === val);
      }
      if (prop === 'lineArticleClient' && supplierProducts.some((p) => p.articleClient === val)) {
        product = supplierProducts.find((p) => p.articleClient === val);
      }
    }
    lineDetails.forEach((l) => {
      if (l.line === line) {
        updatedLine = {
          ...l,
          [prop]: val
        };
        if ((prop === 'lineArticle' || prop === 'lineArticleClient') && product) {
          updatedLine.lineArticle = product.article || '';
          updatedLine.lineArticleClient = product.articleClient || '';
          updatedLine.lineUnitMeasurement = product.unitMeasurement || '';
        }
      }
    });
    if (updatedLine.lineExtraCost === undefined) {
      updatedLine.lineExtraCost = false;
    }
    onUpdateLine(updatedLine);
  };

  const handleOnBlurUnitMeasurementText = (e, line, prop) => {
    const { value } = e.target;
    if (!supplierUnitMeasurementVals.includes(value)) {
      setUnitMeasurementVals([...supplierUnitMeasurementVals, value]);
    } else {
      setUnitMeasurementVals(supplierUnitMeasurementVals);
    }
    handleOnBlur(value, line, prop);
  };

  const handleChangeAutocomplete = (e, line, prop, values) => {
    if (values && values.code) {
      handleOnBlur(values.code, line, prop);
      return;
    }
    handleOnBlur(values, line, prop);
  };

  const validation = (value, prop) => {
    if (!value) {
      return true;
    }
    if (amountRelatedFields.includes(prop) && !amountRegex.test(value)) {
      return false;
    }
    if (!noWhiteSpace.test(value)) {
      return false;
    }
    return true;
  };

  const getCellClassName = (key) => {
    const fieldsLen = preferredFields.filter((field) => field.isActive && field.isAvailable).length;
    if (fieldsLen === 4) {
      if (key === 'lineDescription' || key === 'lineGLAccount') {
        return classes.table2Cell4fieldsLong;
      }
      if (key === 'lineExtraCost') {
        return classes.table2Cell4fieldsVeryShort;
      }
      return classes.table2Cell4fieldsShort;
    }
    if (fieldsLen === 5) {
      if (key === 'lineExtraCost') {
        return classes.table2CellVeryShort;
      }
      if (key === 'lineDescription' || key === 'lineGLAccount') {
        return classes.table2Cell5fieldsLong;
      }
      return classes.table2Cell5fieldsShort;
    }
    if (fieldsLen === 6) {
      if (key === 'lineExtraCost') {
        return classes.table2CellVeryShort;
      }
      if (key === 'lineDescription' || key === 'lineGLAccount') {
        return classes.table2Cell6fieldsLong;
      }
      return classes.table2Cell6fieldsShort;
    }
    if (fieldsLen === 7) {
      if (key === 'lineExtraCost') {
        return classes.table2CellVeryShort;
      }
      if (key === 'lineDescription' || key === 'lineGLAccount') {
        return classes.table2Cell7fieldsLong;
      }
      return classes.table2Cell7fieldsShort;
    }
    if (key === 'lineDescription' || key === 'lineGLAccount') {
      return classes.table2CellLong;
    }
    if (key === 'lineExtraCost') {
      return classes.table2CellVeryShort;
    }
    return classes.table2CellShort;
  };

  const getIsSelectedClassname = (key) => (textFieldSelectedKey === `invoiceLineColumn_${key}` ? classes.textFieldHighlighted : '');

  const cursorPointerClass = (key) => (lineColumnsReprocess.includes(`invoiceLineColumn_${key}`) ? classes.cursorPointer : '');

  const tableHead1 = (
    <TableRow>
      <TableCell className={classes.draggableIconHeader} />
      <TableCell align="center" size="medium" className={classes.columnNumber}>
        {ready && t('INVOICE_EDIT_LINE')}
      </TableCell>
    </TableRow>
  );

  const tableHead2 = (
    <TableRow>
      {preferredFields.filter((field) => field.isActive && field.isAvailable).map((field) => (
        <TableCell
          id={`invoiceLineColumn_${field.key}`}
          key={field.key}
          className={clsx(
            getCellClassName(field.key),
            getIsSelectedClassname(field.key),
            cursorPointerClass(field.key),
            classes.headerHeight
          )}
          onClick={(e) => onFocusField(e, `invoiceLineColumn_${field.key}`)}
        >
          {ready && t(field.label)}
          {lineColumnsReprocess.includes(`invoiceLineColumn_${field.key}`) && (
            <Tooltip title={t('LINE_BOUNDARIES_RESET_TOOLTIP')}>
              <IconButton disabled={isResetLineBoundariesBtnDisabled(field.key)} onClick={() => resetLineBoundaries(field.key)}>
                <ResetIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      ))}
    </TableRow>
  );

  const tableHead3 = (
    <TableRow>
      <TableCell className={classes.draggableIconHeader}>
        <Grid className={classes.actionBtnContainer}>
          <IconButton
            onClick={() => onAddLineConfirm({ line: lineDetails?.length + 1, lineDescription: '', lineAmount: '' })}
            className={classes.iconButton}
          >
            <PlusCircleIcon className={classes.addIcon} />
          </IconButton>
          {isShowManageFields(user, 'applyParentFieldsLinesDisplay') && (
            <IconButton onClick={() => setShowManageFields(true)} className={classes.iconButton}>
              <SettingsIcon className={classes.btnIcon} />
            </IconButton>
          )}
        </Grid>
      </TableCell>
    </TableRow>
  );

  const tableRow1 = (draggableProvided, line) => (
    <TableRow
      key={line.line}
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
    >
      <TableCell align="left" className={classes.draggableIcon}>
        <div {...draggableProvided.dragHandleProps}>
          <ReorderIcon className={classes.reorderIcon} />
        </div>
      </TableCell>
      <TableCell size="medium" className={clsx(classes.colHeight, 'edit-invoice-line-num')}>
        <TextField
          disabled
          value={line?.line || ''}
          variant="outlined"
          className={classes.root}
        />
      </TableCell>
    </TableRow>
  );

  const tableRow2 = (line) => (
    <TableRow key={line.line}>
      {preferredFields.filter((field) => field.isActive && field.isAvailable).map((field) => {
        if (field.key === 'lineGLAccount' || field.key === 'lineVatGroup' || field.key === 'lineCostCenter' || field.key === 'lineProject') {
          return (
            <TableCell key={field.key} className={clsx(getCellClassName(field.key), 'edit-invoice-line')}>
              <Autocomplete
                id={`invoiceLine_${line.line}_${field.key}`}
                fullWidth
                onFocus={(e) => onFocusField(e, `invoiceLine_${line.line}_${field.key}`)}
                onChange={(e, v) => handleChangeAutocomplete(e, line.line, field.key, v)}
                value={getTFLabel(dimensionsLists[field.key], line[field.key])}
                options={dimensionsLists[field.key]}
                getOptionLabel={(option) => (option && option.code ? `${option.code}${option.name ? ` | ${option.name}` : ''}` : option)}
                getOptionSelected={(option) => option.code === (line[field.key] ? line[field.key] : ' ')}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    variant="outlined"
                    className={
                      !validation(line[field.key], field.key)
                        ? classes.errorSelectStyle
                        : textFieldSelectedKey === `invoiceLine_${line.line}_${field.key}`
                          ? classes.selectFieldHighlighted
                          : classes.selectStyle
                    }
                    InputProps={{
                      ...params.InputProps,
                      className: classes.selectInputStyle,
                      classes: {
                        root: classes.inputRoot,
                      }
                    }}
                  />
                )}
              />
            </TableCell>
          );
        }
        if (field.key === 'lineExtraCost') {
          return (
            <TableCell key={field.key} className={clsx(getCellClassName(field.key), 'edit-invoice-line')}>
              <Checkbox
                checked={line?.lineExtraCost || line.lineExtraCost === 'INVOICE_LINE_EDIT_YES'}
                variant="outlined"
                className={classes.checkBox}
                onChange={(e) => handleChange(e.target.checked, line.line, 'lineExtraCost')}
                onFocus={() => setTextFieldSelectedKey('')}
              />
            </TableCell>
          );
        }
        if (field.key === 'lineUnitMeasurement') {
          return (
            <TableCell key={field.key} className={clsx(getCellClassName(field.key), 'edit-invoice-line')}>
              <Autocomplete
                id={`invoiceLine_${line.line}_${field.key}`}
                fullWidth
                onFocus={(e) => onFocusField(e, `invoiceLine_${line.line}_${field.key}`)}
                onChange={(e, v) => handleChangeAutocomplete(e, line.line, field.key, v)}
                value={line[field.key] || ''}
                options={unitMeasurementVals}
                getOptionLabel={(option) => option}
                getOptionSelected={(option) => option === (line[field.key] ? line[field.key] : ' ')}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    variant="outlined"
                    className={
                        !validation(line[field.key], field.key)
                          ? classes.errorSelectStyle
                          : textFieldSelectedKey === `invoiceLine_${line.line}_${field.key}`
                            ? classes.selectFieldHighlighted
                            : classes.selectStyle
                      }
                    InputProps={{
                      ...params.InputProps,
                      className: classes.selectInputStyle,
                      classes: {
                        root: classes.inputRoot,
                      }
                    }}
                    onBlur={(e) => handleOnBlurUnitMeasurementText(e, line.line, field.key)}
                  />
                )}
              />
            </TableCell>
          );
        }
        return (
          <TableCell key={field.key} className={clsx(getCellClassName(field.key), 'edit-invoice-line')}>
            <TextField
              id={`invoiceLine_${line.line}_${field.key}`}
              fullWidth
              value={line[field.key] || ''}
              variant="outlined"
              className={
                !validation(line[field.key], field.key)
                  ? classes.errorState
                  : textFieldSelectedKey === `invoiceLine_${line.line}_${field.key}`
                    ? classes.textFieldHighlited
                    : classes.inputStyle
              }
              classes={{
                root: classes.inputRoot,
              }}
              onChange={(e) => handleChange(e.target.value, line.line, field.key)}
              onBlur={(e) => handleOnBlur(e.target.value, line.line, field.key)}
              onFocus={(e) => onFocusField(e, `invoiceLine_${line.line}_${field.key}`)}
            />
          </TableCell>
        );
      })}
    </TableRow>
  );

  const tableRow3 = (line) => (
    <TableRow key={line.line}>
      <TableCell className={clsx(classes.draggableIcon, 'edit-invoice-line')}>
        <Grid className={classes.actionBtnContainer}>
          <Tooltip title={t('INVOICE_EDIT_FORM_EDIT_INVOICE_LINE_TOOLTIP')}>
            <IconButton onClick={() => onEditLine(line)}>
              <SvgIcon style={{ display: 'flex' }}>
                <EditIcon className={classes.editIcon} />
              </SvgIcon>
            </IconButton>
          </Tooltip>
          <Tooltip title={t('INVOICE_EDIT_FORM_DELETE_INVOICE_LINE_TOOLTIP')}>
            <IconButton onClick={() => onDeleteLine(line)}>
              <SvgIcon style={{ display: 'flex' }}>
                <DeleteIcon className={classes.deleteIcon} />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Grid>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      <Grid item xs={12} className={classes.linesContainer}>
        <Grid className={classes.lineHeader}>
          <Grid className={classes.lineTitleContainer}>
            <Typography className={classes.lineTitle}>
              {ready && t('INVOICE_EDIT_INVOICE_LINES')}
            </Typography>
            {user?.userRole?.toLowerCase() === userRoles.annotator && (
              <FormControlLabel
                value="top"
                control={(
                  <Checkbox
                    className={classes.checkBox}
                    checked={sendLines}
                    onChange={handleSendLinesChange}
                  />
                )}
                label={ready && t('INVOICE_EDIT_LINE_SEND_FOR_TRAINING')}
                labelPlacement="end"
                className={classes.checkBoxContainer}
                classes={{ label: classes.checkBoxLabelSmall }}
              />
            )}
            {user?.customisations?.includes('enableLinesReprocessing') && (
              <Button
                color="secondary"
                variant="outlined"
                className={classes.reprocessingBtn}
                disabled={reprocessingLines || isLinesReprocessingBtnDisabled()}
                onClick={sendLinesForReprocessing}
              >
                {ready && t('INVOICE_EDIT_FORM_SEND_FOR_REPROCESSING_LINES')}
              </Button>
            )}
          </Grid>
          <Tooltip title={t('INVOICE_EDIT_ADD_INVOICE_LINE_TOOLTIP')}>
            <Button
              color="secondary"
              variant="contained"
              className={classes.action}
              onClick={onAddLine}
            >
              <SvgIcon
                fontSize="small"
                className={classes.actionIcon}
              >
                <PlusCircleIcon />
              </SvgIcon>
              {ready && t('INVOICE_EDIT_ADD_INVOICE_LINE')}
            </Button>
          </Tooltip>
        </Grid>
        <Grid className={classes.linesInExtractDataCheckBoxContainer}>
          <FormControlLabel
            value="top"
            control={(
              <Checkbox
                className={classes.checkBox}
                checked={includeLinesInExport}
                onChange={handleIncludeLinesInExportChange}
              />
            )}
            label={ready && t('INVOICE_EDIT_LINE_INCLUDE_IN_EXPORT')}
            labelPlacement="end"
            className={classes.checkBoxContainer}
            classes={{ label: classes.checkBoxLabelSmall }}
          />
        </Grid>
        {
          lineDetails && lineDetails.length > 0 ? (
            <Grid className={classes.tablesContainer}>
              <Paper
                ref={tableRefs.table1Ref}
                className={clsx(classes.table1, 'hide-scroll')}
                onScroll={() => handleScroll('table1Ref', 'table2Ref', 'table3Ref')}
              >
                <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    {tableHead1}
                  </TableHead>
                  <DragDropContext onDragEnd={(e) => adjustLinesOrder(e?.source?.index, e?.destination?.index)}>
                    <Droppable droppableId="droppable" direction="vertical">
                      {
                      (droppableProvided) => (
                        <TableBody
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.droppableProps}
                        >
                          {
                            lineDetails.sort((a, b) => (a.line > b.line ? 1 : -1)).map((line) => (
                              <Draggable
                                key={line.line}
                                draggableId={line.line?.toString()}
                                index={line.line}
                              >
                                {(draggableProvided) => (tableRow1(draggableProvided, line))}
                              </Draggable>
                            ))
                          }
                          {droppableProvided.placeholder}
                        </TableBody>
                      )
                    }
                    </Droppable>
                  </DragDropContext>
                </Table>
              </Paper>
              <Paper
                ref={tableRefs.table2Ref}
                className={classes.table2}
                onScroll={() => handleScroll('table2Ref', 'table1Ref', 'table3Ref')}
              >
                <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    {tableHead2}
                  </TableHead>
                  <TableBody>
                    {
                      lineDetails.sort((a, b) => (a.line > b.line ? 1 : -1)).map((line) => (
                        tableRow2(line)
                      ))
                    }
                  </TableBody>
                </Table>
              </Paper>
              <Paper
                ref={tableRefs.table3Ref}
                className={clsx(classes.table3, 'hide-scroll')}
                onScroll={() => handleScroll('table3Ref', 'table1Ref', 'table2Ref')}
              >
                <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    {tableHead3}
                  </TableHead>
                  <TableBody>
                    {
                      lineDetails.sort((a, b) => (a.line > b.line ? 1 : -1)).map((line) => (
                        tableRow3(line)
                      ))
                    }
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          ) : <EmptyText str="INVOICE_EDIT_FORM_NO_INVOICE_LINES" />
        }
      </Grid>
      <ManageFields
        open={showManageFields}
        entity="invoice-line-display"
        fields={preferredFields}
        fetchFields={getLinesDisplayFields}
        closeModal={handleCloseManageFields}
        parameterCompany={parameterCompany}
      />
    </>
  );
};

DisplayLines.propTypes = {
  t: PropTypes.func,
  ready: PropTypes.bool,
  preferredFields: PropTypes.array,
  getLinesDisplayFields: PropTypes.func,
  sendLines: PropTypes.bool,
  handleSendLinesChange: PropTypes.func,
  onAddLine: PropTypes.func,
  details: PropTypes.object,
  isTwinfield: PropTypes.bool,
  adjustLinesOrder: PropTypes.func,
  getTFLabel: PropTypes.func,
  glAccounts: PropTypes.array,
  vatGroups: PropTypes.array,
  costCenters: PropTypes.array,
  projects: PropTypes.array,
  onEditLine: PropTypes.func,
  onDeleteLine: PropTypes.func,
  includeLinesInExport: PropTypes.bool,
  handleIncludeLinesInExportChange: PropTypes.func,
  onUpdateLine: PropTypes.func,
  parameterCompany: PropTypes.string,
  onAddLineConfirm: PropTypes.func,
  textFieldSelectedKey: PropTypes.string,
  onFocusField: PropTypes.func,
  supplier: PropTypes.object,
  setTextFieldSelectedKey: PropTypes.func,
  reprocessingLines: PropTypes.bool,
  sendLinesForReprocessing: PropTypes.func,
  isLinesReprocessingBtnDisabled: PropTypes.func,
  isResetLineBoundariesBtnDisabled: PropTypes.func,
  resetLineBoundaries: PropTypes.func,
};

export default DisplayLines;
