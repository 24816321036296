import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    maxHeight: '100vh',
    overflow: 'auto'
  },
  primaryTextColor: {
    color: '#334D6E',
  },
  createButton: {
    display: 'flex',
    height: 32,
    textTransform: 'none',
    color: '#ffffff',
    backgroundColor: '#3E8AFF',
    padding: '0px 16px'
  },
  navButton: {
    height: 42,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: '#334D6E',
    padding: '0px 15px',
    border: '1px #e4e4e4 solid',
  },
  manageFieldsContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '12px 12px 0px 12px'
  },
  manageFieldsBtn: {
    height: 36,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: '#334D6E',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
    border: '1px rgba(0, 0, 0, 0.23) solid',
    borderRadius: 8,
    fontSize: '12px',
    marginRight: '12px'
  },
  btnIcon: {
    height: 15,
    width: 15,
    marginRight: 4,
    color: '#bebebe',
  },
  btnIconArrowLeft: {
    height: 36,
    width: 36,
    marginLeft: -16,
    marginRight: -8,
    color: '#bebebe',
  },
  btnIconArrowRight: {
    height: 36,
    width: 36,
    marginRight: -16,
    marginLeft: -8,
    color: '#bebebe',
  },
  plusIcon: {
    height: 24,
    width: 24,
    color: '#3E8AFF',
    cursor: 'pointer'
  },
  plusIconContainer: {
    marginRight: -60
  },
  editDimensionIcon: {
    fontSize: 18,
    color: '#757575',
    border: '1px solid #334D6E',
    borderRadius: 3,
    cursor: 'default'
  },
  editIconContainer: {
    marginRight: -5
  },
  viewDimensionIcon: {
    fontSize: 18,
    color: '#334D6E',
    border: '1px solid #334D6E',
    borderRadius: 3,
    cursor: 'default'
  },
  viewIconContainer: {
    marginRight: -56
  },
  expandIcon: {
    width: 18,
    height: 18,
    color: '#334D6E',
    cursor: 'pointer'
  },
  expandIconContainer: {
    cursor: 'pointer',
    padding: 0,
    width: 20,
    height: 20,
    minWidth: 20,
    borderRadius: 12,
    border: '1px solid rgba(194, 207, 224, 0.6)',
  },
  dividerDiv: {
    borderBottom: '2px solid rgba(194, 207, 224, 0.6)',
    width: '100%'
  },
  dividerDivBtn: {
    borderBottom: '2px solid rgba(194, 207, 224, 0.6)',
    width: '15%'
  },
  dividerSpan: {
    padding: '0 4px'
  },
  vatDetailsBtn: {
    cursor: 'pointer',
    textTransform: 'none',
    width: 112,
    padding: 0
  },
  surchargeDetailsBtn: {
    cursor: 'pointer',
    textTransform: 'none',
    width: 156,
    padding: 0
  },
  vatTableTitle: {
    color: 'rgba(51, 77, 110, 0.5)!important',
    fontSize: 11,
    paddingRight: 4
  },
  addUserDisabled: {
    marginBottom: 10,
    marginTop: 10,
  },
  addUserDisabledText: {
    fontSize: 11,
  },
  warningBanner: {
    width: '100%',
    paddingLeft: 20,
  },
  pdfTop: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  instructionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#e3e3e3',
    padding: '4px 8px',
    borderRadius: 8,
  },
  instructions: {
    color: '#000000',
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    margin: '2px 0px'
  },
  keyIcon: {
    border: '1px #000000 solid',
    fontSize: 12,
    margin: '0px 8px',
    borderRadius: 8,
    padding: '0px 2px',
    height: 20
  },
  pdfPage: {
    overflow: 'scroll'
  },
  leftContainerMobile: {
    maxHeight: '920px'
  },
  leftContainer: {
    maxHeight: '95vh',
  },
  cardContent: {
    backgroundColor: '#ffffff',
    padding: '20px 8px 0px'
  },
  invoiceImg: {
    maxHeight: 614,
    objectFit: 'contain'
  },
  invoicePdf: {
    height: 614,
    maxHeight: 614,
    width: '100%',
    objectFit: 'contain'
  },
  inputContainerMobile: {
    padding: '0px 16px',
    maxHeight: '410px',
    overflow: 'scroll'
  },
  inputContainer: {
    padding: '0px 16px',
    maxHeight: '42vh',
    overflow: 'scroll'
  },
  inputContainerWithExportFailReason: {
    padding: '0px 16px',
    maxHeight: '35vh',
    overflow: 'scroll'
  },
  navButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 12px 12px 12px',
    margin: '0px -8px',
    backgroundColor: '#ffffff'
  },
  snackAction: {
    color: 'white'
  },
  textFieldSelect: {
    backgroundColor: '#e8e8e8'
  },
  textFieldHighlighted: {
    backgroundColor: '#d7e7fc'
  },
  invoiceContainer: {
    padding: '0px',
    backgroundColor: 'gray'
  },
  pdfFrame: {
    width: '100%',
    height: '100%',
    borderWidth: 0,
    backgroundColor: 'gray'
  },
  tabsContainer: {
    backgroundColor: '#ffffff',
    paddingLeft: 40
  },
  inputItem: {
    padding: '8px 16px 16px',
    '& div > div > fieldset': {
      border: '2px solid rgba(194, 207, 224, 0.6)!important'
    }
  },
  inputItemCompress: {
    padding: '8px 16px 8px',
    '& div > div > fieldset': {
      border: '2px solid rgba(194, 207, 224, 0.6)!important'
    }
  },
  errorRow: {
    padding: '0px 16px 8px 16px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center'
  },
  errorText: {
    color: '#f44336',
    fontSize: '11px !important'
  },
  inputItemRow: {
    padding: '8px 16px 16px',
    '& div > div > fieldset': {
      border: '2px solid rgba(194, 207, 224, 0.6)!important'
    },
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center'
  },
  inputItemRowEnd: {
    padding: '0px 16px 16px 0px',
    '& div > div > fieldset': {
      border: '2px solid rgba(194, 207, 224, 0.6)!important'
    },
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  inputItemRowSpace: {
    padding: '8px 16px 16px',
    '& div > div > fieldset': {
      border: '2px solid rgba(194, 207, 224, 0.6)!important'
    },
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  vatLineField: {
    width: '25%'
  },
  vatLineFieldError: {
    width: '25%',
    backgroundColor: '#fcc9a7'
  },
  vatLineFieldHighlighted: {
    width: '25%',
    backgroundColor: '#d7e7fc'
  },
  vatLineFieldTaxName: {
    width: '100%'
  },
  vatLineFieldErrorTaxName: {
    width: '100%',
    backgroundColor: '#fcc9a7'
  },
  vatLineFieldHighlightedTaxName: {
    width: '100%',
    backgroundColor: '#d7e7fc'
  },
  vatDetailsContainerTop: {
    padding: '8px 16px 0px 0px',
    '& div > div > fieldset': {
      border: '2px solid rgba(194, 207, 224, 0.6)!important'
    },
    display: 'flex',
    flexDirection: 'row'
  },
  elem1: {
    width: '16px',
    height: '21px'
  },
  elem2: {
    width: '16px',
    height: '100%',
    borderLeft: '2px solid rgba(194, 207, 224, 0.6)',
    borderTop: '2px solid rgba(194, 207, 224, 0.6)',
  },
  elem3: {
    width: '1x',
    height: '50%',
    borderLeft: '2px solid rgba(194, 207, 224, 0.6)',
  },
  elem4: {
    width: '1px',
    height: '50%',
  },
  vatDetailsContainer: {
    padding: '16px 0px 0px 0px',
    borderLeft: '2px solid rgba(194, 207, 224, 0.6)',
    width: '100%',
    border: '0px solid rgba(194, 207, 224, 0.6)',
  },
  rightTitle: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: '16px',
    color: 'rgba(51, 77, 110, 0.5)',
    marginBottom: 30
  },
  lineTitle: {
    fontSize: 16,
    color: '#334D6E',
    padding: '8px 0px 0px',
    marginRight: '32px'
  },
  lineNumber: {
    fontSize: 14,
    color: 'rgba(51, 77, 110, 0.5)',
    padding: '8px 0px',
  },
  linesContainer: {
    padding: '16px 32px 32px',
    backgroundColor: '#ffffff',
    boxShadow: '0px -2px 4px rgb(0 0 0 / 8%)',
  },
  pdfViewer: {
    width: '100%',
    height: 800
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordionInputItem: {
    '& div > div > fieldset': {
      border: '2px solid rgba(194, 207, 224, 0.6)!important'
    }
  },
  pdfContainer: {
    backgroundColor: 'gray',
    height: '88%',
    padding: '0px 12px 10px 12px',
    overflow: 'scroll',
    minHeight: '360px'
  },
  buttonCls: {
    cursor: 'pointer',
    marginRight: '2px',
    minWidth: 48,
    color: '#ffffff'
  },
  btnGroup: {
    width: '100%',
    marginTop: 10,
  },
  topBar: {
    padding: '12px 40px',
    color: '#ffffff'
  },
  xmlNavBtnsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  documentStyle: {
    height: '100%',
    width: '100%'
  },
  backdrop: {
    zIndex: 1111,
    color: '#ffffff',
  },
  formControl: {
    width: '100%'
  },
  inputText: {
    color: '#334D6E',
    fontSize: '13px',
    lineHeight: '20px'
  },
  action: {
    fontSize: 14,
    fontWeight: 600,
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
  },
  actionIcon: {
    color: 'rgba(255, 255, 255, 0.8)',
    marginRight: theme.spacing(1)
  },
  deleteIcon: {
    fontSize: 16,
    color: '#F7685B',
  },
  deleteIconContainer: {
    padding: 0
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  checkBoxContainer: {
    display: 'flex',
    minWidth: 130
  },
  checkBoxLabel: {
    fontSize: 14,
    color: '#334D6E'
  },
  checkBoxLabelSmall: {
    fontSize: 12,
    color: '#334D6E'
  },
  checkBox: {
    color: 'rgba(0, 0, 0, 0.26)',
    fontWeight: 500
  },
  spreadInvoiceContainer: {
    width: '100%',
    justifyContent: 'flex-end',
    display: 'flex'
  },
  searchInput: {
    textAlign: 'center',
    color: '#fff!important',
    width: '50px',
    marginRight: '5px',
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
      backgroundColor: '#fcfcfb 0.5',
      color: 'white!important',
    },
    '& .MuiOutlinedInput-root': {
      height: '30px!important',
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white'
      },
    },
  },
  orangeHelperText: {
    color: '#fa7e02',
  },
  vatNumberRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  countryCodeField: {
    marginRight: 10,
    width: '20%'
  },
  taxNumberSwitchText: {
    fontSize: 12,
    color: '#3E8AFF',
    textAlign: 'end',
    fontWeight: 500,
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  countryCodePaper: {
    width: '180px'
  },
  countryCodeOption: {
    fontSize: 13,
    color: '#334D6E',
  }
}));

export default useStyles;
