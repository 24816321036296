import React, {
  useState, useEffect, useRef, useContext
} from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import LoadingScreenText from 'src/Shared/components/LoadingScreenText/LoadingScreenText';
import InvoicesListResults from 'src/Dashboard/components/InvoicesListResults/InvoicesListResults';

import authService from 'src/Shared/utils/services/authService';
import { axiosHeaders, validateToken } from 'src/Shared/utils/helpers';
import { setUserData } from 'src/Auth/store/actions/auth';
import { invoicePageRefreshTimer, invoiceStatusesKeys } from 'src/config';
import { getInvoiceAppStatsByUser } from 'src/Dashboard/store/actions/dashboard';
import { useTranslation } from 'react-i18next';
import ConfigContext from 'src/Contexts';

function InvoiceListView(props) {
  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [containsFailedToExport, setContainsFailedToExport] = useState(false);
  const [containsIsDuplicate, setContainsIsDuplicate] = useState(false);
  const [containsPossibleDuplicate, setContainsPossibleDuplicate] = useState(false);

  const [page, _setPage] = useState(0);
  const pageRef = useRef(page);
  const setPage = (val) => {
    pageRef.current = val;
    _setPage(val);
  };

  const [limit, _setLimit] = useState(10);
  const limitRef = useRef(limit);
  const setLimit = (val) => {
    limitRef.current = val;
    _setLimit(val);
  };

  const [search, _setSearch] = useState('');
  const searchRef = useRef(search);
  const setSearch = (val) => {
    searchRef.current = val;
    _setSearch(val);
  };

  const [sortBy, _setSortBy] = useState('');
  const sortByRef = useRef(sortBy);
  const setSortBy = (val) => {
    sortByRef.current = val;
    _setSortBy(val);
  };

  const [order, _setOrder] = useState('desc');
  const orderRef = useRef(order);
  const setOrder = (val) => {
    orderRef.current = val;
    _setOrder(val);
  };

  const [filter, _setFilter] = useState('');
  const filterRef = useRef(filter);
  const setFilter = (val) => {
    filterRef.current = val;
    _setFilter(val);
  };

  const [time, _setTime] = useState('');
  const timeRef = useRef(time);
  const setTime = (val) => {
    timeRef.current = val;
    _setTime(val);
  };

  const [suppliers, _setSuppliers] = useState('');
  const suppliersRef = useRef(suppliers);
  const setSuppliers = (val) => {
    suppliersRef.current = val;
    _setSuppliers(val);
  };

  const [owners, _setOwners] = useState('');
  const ownersRef = useRef(owners);
  const setOwners = (val) => {
    ownersRef.current = val;
    _setOwners(val);
  };

  const [startTime, _setStartTime] = useState('');
  const startTimeRef = useRef(startTime);
  const setStartTime = (val) => {
    startTimeRef.current = val;
    _setStartTime(val);
  };

  const [endTime, _setEndTime] = useState('');
  const endTimeRef = useRef(endTime);
  const setEndTime = (val) => {
    endTimeRef.current = val;
    _setEndTime(val);
  };

  const [failedToExport, _setFailedToExport] = useState(false);
  const failedToExportRef = useRef(failedToExport);
  const setFailedToExport = (val) => {
    failedToExportRef.current = val;
    _setFailedToExport(val);
  };

  const [isThisDuplicate, _setIsDuplicate] = useState(false);
  const isDuplicateRef = useRef(isThisDuplicate);
  const setIsDuplicate = (val) => {
    isDuplicateRef.current = val;
    _setIsDuplicate(val);
  };

  const [isThisPossibleDuplicate, _setIsPossibleDuplicate] = useState(false);
  const isPossibleDuplicateRef = useRef(isThisPossibleDuplicate);
  const setIsPossibleDuplicate = (val) => {
    isPossibleDuplicateRef.current = val;
    _setIsPossibleDuplicate(val);
  };

  const [companies, _setCompanies] = useState([]);
  const companiesRef = useRef(companies);
  const setCompanies = (val) => {
    companiesRef.current = val;
    _setCompanies(val);
  };

  const [invoices, setInvoices] = useState([]);
  const intervalId = useRef(null);
  let lastURL = '';

  const { onDataLoaded } = props;

  const onClearInterval = () => {
    clearInterval(intervalId.current);
  };

  const setTimePeriod = (newTime) => {
    setTime(newTime);
  };

  const getInvoices = async (
    thisPage = 0,
    thisLimit = 10,
    thisSearch = '',
    thisFilter = '',
    thisTime = '',
    suppliersList = [],
    ownersList = [],
    thisStartTime = '',
    thisEndTime = '',
    thisFailedToExport = false,
    thisDuplicate = false,
    thisPossibleDuplicate = false,
    thisSortBy = '',
    thisOrder = 'desc',
    companiesList = []
  ) => {
    let thisSuppliers = '';
    if (Array.isArray(suppliersList)) {
      thisSuppliers = suppliersList.map((supplier) => supplier.creditorCode).join(',');
    } else if (suppliersList) {
      thisSuppliers = suppliersList;
    }

    let thisOwners = '';
    if (Array.isArray(ownersList)) {
      thisOwners = ownersList.map((owner) => owner.email).join(',');
    } else if (ownersList) {
      thisOwners = ownersList;
    }

    let thisCompanies = [];
    if (companiesList.length > 0 && companiesList[0].companyId) {
      thisCompanies = companiesList.map((company) => company.companyId);
    } else if (companiesList.length > 0) {
      thisCompanies = companiesList;
    }

    if (!validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setPage(thisPage);
    setLimit(thisLimit);
    setSearch(thisSearch);
    setTime(thisTime);
    setSuppliers(thisSuppliers);
    setOwners(thisOwners);
    setStartTime(thisStartTime);
    setEndTime(thisEndTime);
    setFailedToExport(thisFailedToExport);
    setIsDuplicate(thisDuplicate);
    setIsPossibleDuplicate(thisPossibleDuplicate);
    setSortBy(thisSortBy);
    setOrder(thisOrder);
    setCompanies(thisCompanies);

    try {
      let url = `${API.getAllInvoices}/${encodeURIComponent(user.companyID)}?page=${thisPage + 1}&limit=${thisLimit}`
        + `&order=${thisOrder}${thisSortBy ? `&sortby=${thisSortBy}` : ''}${thisSearch
          && thisSearch !== '' ? `&search=${thisSearch}` : ''}${thisFilter
          && thisFilter !== '' ? `&filter=${thisFilter}` : ''}${thisFailedToExport
          ? `&failedToExport=${thisFailedToExport.toString()}` : ''}`;
      if (thisStartTime && thisStartTime !== '' && thisEndTime && thisEndTime !== '') {
        url = `${url}&startTime=${thisStartTime}&endTime=${thisEndTime}`;
      } else {
        url = `${url}${thisTime && thisTime !== '' ? `&time=${thisTime}` : ''}`;
      }
      lastURL = url;

      const body = {
        suppliers: thisSuppliers,
        owners: thisOwners,
        isDuplicate: thisDuplicate,
        isPossibleDuplicate: thisPossibleDuplicate,
        companies: thisCompanies
      };
      const response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response && response.config && response.config.url === lastURL) {
        if (response.data.success) {
          dispatch(getInvoiceAppStatsByUser());
          setContainsFailedToExport(response.data.containsFailedToExport);
          if (response.data.data.length > 0) {
            setInvoices(response.data.data);
            setTotal(response.data.total);
            for (let i = 0; i < response.data.data.length; i++) {
              if (response.data.data[i].isDuplicate) {
                setContainsIsDuplicate(true);
              }
              if (response.data.data[i].status === 201) {
                setContainsPossibleDuplicate(true);
              }
            }
          } else {
            setInvoices([]);
            setTotal(0);
          }

          // Update invoices count
          const updatedUser = {
            ...user,
            invoicesCount: response.data.invoicesUsed,
          };
          dispatch(setUserData(updatedUser));
        } else {
          setInvoices([]);
          setTotal(0);
        }
        onDataLoaded();
      }
    } catch (error) {
      setInvoices([]);
      setTotal(0);
      onDataLoaded();
    }
    setLoading(false);
  };

  const getArchivedInvoices = async (
    thisPage = 0,
    thisLimit = 10,
    thisSearch = '',
    thisTime = '',
    thisStartTime = '',
    thisEndTime = '',
    thisSortBy = '',
    thisOrder = 'desc'
  ) => {
    if (!validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setPage(thisPage);
    setLimit(thisLimit);
    setSearch(thisSearch);
    setTime(thisTime);
    setStartTime(thisStartTime);
    setEndTime(thisEndTime);
    setSortBy(thisSortBy);
    setOrder(thisOrder);
    setOwners('');
    setSuppliers('');
    setIsDuplicate(false);
    setIsPossibleDuplicate(false);
    setCompanies([]);

    try {
      let url = `${API.getArchivedInvoices}/${encodeURIComponent(user.companyID)}`
      + `?page=${thisPage + 1}&limit=${thisLimit}&order=${thisOrder}`
      + `${thisSortBy ? `&sortby=${thisSortBy}` : ''}${thisSearch
        && thisSearch !== '' ? `&search=${thisSearch}` : ''}`;
      if (thisStartTime && thisStartTime !== '' && thisEndTime && thisEndTime !== '') {
        url = `${url}&startTime=${thisStartTime}&endTime=${thisEndTime}`;
      } else {
        url = `${url}${thisTime && thisTime !== '' ? `&time=${thisTime}` : ''}`;
      }
      lastURL = url;
      const response = await Axios.post(url, {}, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response && response.config && response.config.url === lastURL) {
        if (response.data.success) {
          if (response.data.data.length > 0) {
            setInvoices(response.data.data);
            setTotal(response.data.total);
            setContainsFailedToExport(response.data.containsFailedToExport);
          } else {
            setInvoices([]);
            setTotal(0);
            setContainsFailedToExport(false);
          }
        } else {
          setInvoices([]);
          setTotal(0);
        }
        onDataLoaded();
      }
    } catch (error) {
      setInvoices([]);
      setTotal(0);
      onDataLoaded();
    }
    setLoading(false);
  };

  const onStartInterval = () => {
    intervalId.current = setInterval(() => {
      if (filterRef.current === invoiceStatusesKeys.archived) {
        getArchivedInvoices(
          pageRef.current,
          limitRef.current,
          searchRef.current,
          timeRef.current,
          startTimeRef.current,
          endTimeRef.current,
          sortByRef.current,
          orderRef.current
        );
      } else {
        getInvoices(
          pageRef.current,
          limitRef.current,
          searchRef.current,
          filterRef.current,
          timeRef.current,
          suppliersRef.current,
          ownersRef.current,
          startTimeRef.current,
          endTimeRef.current,
          failedToExportRef.current,
          isDuplicateRef.current,
          isPossibleDuplicateRef.current,
          sortByRef.current,
          orderRef.current,
          companiesRef.current
        );
      }
    }, invoicePageRefreshTimer);
  };

  useEffect(() => {
    getInvoices(0, 10);
    onStartInterval();
    return () => clearInterval(intervalId.current);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <LoadingScreenText />;
  }

  return (
    <InvoicesListResults
      invoices={invoices}
      total={total}
      getInvoices={getInvoices}
      time={time}
      getArchivedInvoices={getArchivedInvoices}
      setTime={setTimePeriod}
      onClearInterval={onClearInterval}
      onStartInterval={onStartInterval}
      changeFilter={setFilter}
      isThisDuplicate={isThisDuplicate}
      setIsDuplicate={setIsDuplicate}
      containsFailedToExport={containsFailedToExport}
      containsIsDuplicate={containsIsDuplicate}
      containsPossibleDuplicate={containsPossibleDuplicate}
    />
  );
}

InvoiceListView.propTypes = {
  onDataLoaded: PropTypes.func,
};

InvoiceListView.defaultProps = {};

export default InvoiceListView;
