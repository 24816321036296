import { userRoles } from 'src/config';

export const isStatusChangeValid = (from, to, isToValidateStatusAllowed) => {
  if ((from === 200 || from === 201) && to === 900) {
    return true;
  }
  if (from === 500) {
    if (!isToValidateStatusAllowed && (to === 502 || to === 510)) {
      return true;
    }
    if (isToValidateStatusAllowed && (to === 501 || to === 510)) {
      return true;
    }
  }
  if (from === 509 && (to === 502 || to === 510)) {
    return true;
  }
  if (from === 501 && isToValidateStatusAllowed && (to === 500 || to === 502 || to === 510)) {
    return true;
  }
  if (from === 502) {
    if (!isToValidateStatusAllowed && (to === 500 || to === 503 || to === 510)) {
      return true;
    }
    if (isToValidateStatusAllowed && (to === 501 || to === 503 || to === 510)) {
      return true;
    }
  }
  if (from === 503 && (to === 502 || to === 510 || to === 600)) {
    return true;
  }
  if (from === 510 && (to === 500 || to === 600 || to === 900)) {
    return true;
  }
  if (from === 600 && (to === 500 || to === 502 || to === 900)) {
    return true;
  }
  if ((from <= 100 && from > 16) && to === 900) {
    return true;
  }
  return false;
};

export const isStatusChangeAble = (status, role) => {
  if (!role) {
    return false;
  }

  switch (role.toLowerCase()) {
    case userRoles.admin:
      if (status === 500 || status === 509 || status === 501 || status === 502 || status === 503 || status === 510
        || (status <= 100 && status > 16) || status === 600) {
        return true;
      }
      return false;

    case userRoles.executive:
      if (status === 500 || status === 509 || status === 501 || status === 502 || status === 510) {
        return true;
      }
      return false;

    case userRoles.manager:
      if (status === 500 || status === 509 || status === 501 || status === 502 || status === 503 || status === 510) {
        return true;
      }
      return false;

    case userRoles.super:
      if (
        status === 500 || status === 509 || status === 501 || status === 502 || status === 503 || status === 510
        || (status <= 100 && status > 16) || status === 600
      ) {
        return true;
      }
      return false;

    default:
      return false;
  }
};

export const isDropAllowed = (status, to) => {
  switch (to) {
    case 500:
      if (status === 500 || status === 501) {
        return '';
      }
      return 'APP_INVALID_INVOICE_STATUS_CHANGE_TOREV';
    case 509:
      if (status === 500 || status === 509 || status === 503) {
        return '';
      }
      return 'APP_INVALID_INVOICE_STATUS_CHANGE_REVED';

    case 501:
      if (status === 500 || status === 501 || status === 502) {
        return '';
      }
      return 'APP_INVALID_INVOICE_STATUS_CHANGE_TOVAL';

    case 502:
      if (status === 501 || status === 502 || status === 503) {
        return '';
      }
      return 'APP_INVALID_INVOICE_STATUS_CHANGE_TOEXP';

    case 503:
      if (status === 502 || status === 503) {
        return '';
      }
      return 'APP_INVALID_INVOICE_STATUS_CHANGE_EXP';

    default:
      return 'APP_INVALID_INVOICE_STATUS_CHANGE';
  }
};
