/* eslint-disable no-nested-ternary */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button, Tooltip, ButtonGroup, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, Box,
} from '@material-ui/core';
import {
  ArrowDropDown as ArrowDropDownIcon,
} from '@material-ui/icons';
import {
  isActionPermitted,
  permissions,
} from 'src/Shared/utils/helpers';
import { dateRelatedFields, documentTypes } from 'src/config';
import useStyles from './style';

const InvoiceEditFormActionButtons = (props) => {
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  const { ready, t } = useTranslation();
  const {
    invoice,
    setSendEmailModalOpen,
    isToValidateStatusAllowed,
    touched,
    saveBtnRef,
    handleAutoSaveInvoice,
    statusChangeLoading,
    deliverFormats,
    handleDataFormatToExportClick,
    isSubmitClicked,
    details,
    isTwinfield,
    vatLines,
    esLines,
    handleSubmit,
    renderedFields,
    getIsError,
  } = props;

  const isAutoSaveEnabled = user.customisations.includes('autoSaveDocument');
  const isISODateFormat = user.customisations.includes('isoDate');
  const decimalSeparator = user.monetaryDecimalSeparator;
  // Regex patterns
  const amountRegex = new RegExp(`^-?\\d*[${decimalSeparator === '.' ? '.' : `.${decimalSeparator}`}]?\\d+$`);
  const email = /^[^\s@]+@[^\s@]+\.[^\s@]{1,}$/;
  const numbersOnly = /^\d+$/;
  const vatRegex = /^[A-Z0-9]+$/;
  // eslint-disable-next-line
  const urlRegex = /^[-a-zA-Z0-9@:/%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
  const currencyRegex = /^[A-Za-z]{2,3}$/;

  const dataFormatOptionsToExportRef = useRef(null);
  const [dataFormatOptionsToExportOpen, setDataFormatOptionsToExportOpen] = useState(false);

  const invalidTwinfieldSupplier = (isTwinfield
    && (details.documentType === documentTypes.invoice
    || details.documentType === documentTypes.creditNote)
    && !details.supplier);

  const isButtonDisabled = () => isSubmitClicked || invoice.status < 500 || invalidTwinfieldSupplier || renderedFields.some((field) => getIsError(field));

  const isValidateDisabled = () => invoice.status < 500 || invalidTwinfieldSupplier || renderedFields.some((field) => getIsError(field));

  const isSubmitBtnDisabled = () => {
    if (vatLines?.some((vl) => (['baseAmount', 'vatRate', 'vatAmount'].some((k) => !amountRegex.test(vl[k]))))) {
      return true;
    }
    if (esLines?.some((vl) => (['baseAmount', 'esRate', 'esAmount'].some((k) => !amountRegex.test(vl[k]))))) {
      return true;
    }

    if (isAutoSaveEnabled && isActionPermitted(permissions.invoiceSave, user.permissions)) {
      return false;
    }

    return isButtonDisabled() || !isActionPermitted(permissions.invoiceSave, user.permissions);
  };

  const getTooltipTitle = () => (
    ((details.senderEmail !== undefined && details.senderEmail !== '')
      && (touched.senderEmail
        || (!email.test(details.senderEmail)
        && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_EMAIL'))))
    || ((details.senderKvk !== undefined
        && details.senderKvk !== '')
        && (touched.senderKvk
        || (!numbersOnly.test(details.senderKvk)
        && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_KVK'))))
    || ((details.senderWebsite !== undefined
        && details.senderWebsite !== '')
        && (touched.senderWebsite
        || (!urlRegex.test(details.senderWebsite)
        && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_URL'))))
    || ((details.senderVatNumber !== undefined
        && details.senderVatNumber !== '')
        && (touched.senderVatNumber
        || details.senderVatNumber === ''
        || (!vatRegex.test(details.senderVatNumber)
        && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_VAT'))))
    || ((details.amount !== undefined
        && details.amount !== '')
        && (touched.amount
        || (!amountRegex.test(details.amount)
        && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_AMOUNT'))))
    || ((details.vatAmount !== undefined
        && details.vatAmount !== '')
        && (touched.vatAmount
        || (!amountRegex.test(details.vatAmount)
        && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_VAT_AMOUNT'))))
    || ((details.discountAmount !== undefined
        && details.discountAmount !== '')
        && (touched.discountAmount
        || (!amountRegex.test(details.discountAmount)
        && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_DISCOUNT_AMOUNT'))))
    || ((details.currency !== undefined
        && details.currency !== '')
        && (touched.currency
        || (!currencyRegex.test(details.currency)
        && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_CURRENCY'))))
    || (renderedFields.some((field) => (dateRelatedFields.includes(field) && getIsError(field)))
      && (isISODateFormat ? t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_DATE_DOCUNECTA') : t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_DATE')))
    || (details?.description?.length > 64
        && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_DESCRIPTION'))
    || (invalidTwinfieldSupplier && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_SUPPLIER'))
    || (isSubmitClicked && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_NO_EDIT'))
    || t('NO_WHITE_SPACE_BTN_TOOLTIP')
  );

  const handleDataformatOptionsToExportToggle = () => {
    setDataFormatOptionsToExportOpen((prevOpen) => !prevOpen);
  };

  const handleDataformatOptionsToExportClose = (event) => {
    if (dataFormatOptionsToExportRef.current && dataFormatOptionsToExportRef.current.contains(event.target)) {
      return;
    }
    setDataFormatOptionsToExportOpen(false);
  };

  const handleSave = async () => {
    try {
      await handleSubmit();
    } catch (e) {
      // do nothing
    }
  };

  return (
    <Box mt={3} className={classes.buttonContainer}>
      {(invoice.status === 500
          || invoice.status === 509
          || invoice.status === 501
          || invoice.status === 502
      ) && (
        <Tooltip
          disableHoverListener={!isButtonDisabled() && isActionPermitted(permissions.invoiceSave, user.permissions)}
          title={!isActionPermitted(permissions.invoiceSave, user.permissions)
            ? t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_PERMISSION')
            : (isButtonDisabled() && !isAutoSaveEnabled)
              ? getTooltipTitle()
              : ''}
        >
          <div>
            <Button
              ref={saveBtnRef}
              variant="outlined"
              className={classes.saveButton}
              onClick={handleSave}
              disabled={isSubmitBtnDisabled()}
            >
              {ready && t('INVOICE_EDIT_FORM_SAVE')}
            </Button>
          </div>
        </Tooltip>
      )}
      {(invoice.status === 500 && isToValidateStatusAllowed) && (
        <Tooltip
          title={!isActionPermitted(permissions.invoiceSave, user.permissions)
            ? t('INVOICE_EDIT_FORM_REVIEW_TOOLTIP_PERMISSION')
            : ''}
        >
          <div>
            <Button
              className={classes.sendToValidateButton}
              variant="contained"
              color="secondary"
              onClick={() => handleAutoSaveInvoice(501)}
              disabled={isValidateDisabled() || !isActionPermitted(permissions.invoiceReview, user.permissions)}
            >
              {ready && t('INVOICE_EDIT_FORM_REVIEW')}
            </Button>
          </div>
        </Tooltip>
      )}
      {(invoice.status === 500 || invoice.status === 509) && !isToValidateStatusAllowed && (
        <>
          <Tooltip
            title={!isActionPermitted(permissions.invoiceValidate, user.permissions)
              ? t('INVOICE_EDIT_FORM_VALIDATE_TOOLTIP_PERMISSION')
              : ''}
          >
            <div>
              <Button
                className="btn-reject"
                variant="outlined"
                onClick={() => handleAutoSaveInvoice(510)}
                disabled={isValidateDisabled() || !isActionPermitted(permissions.invoiceValidate, user.permissions) || statusChangeLoading}
              >
                {ready && t('INVOICE_EDIT_FORM_REJECT')}
              </Button>
            </div>
          </Tooltip>
          <Tooltip
            title={!isActionPermitted(permissions.invoiceExport, user.permissions)
              ? t('INVOICE_EXPORT_TOOLTIP_NO_PERMISSION')
              : ''}
          >
            <div>
              <Button
                className={classes.sendToValidateButton}
                variant="contained"
                color="secondary"
                onClick={() => handleAutoSaveInvoice(502)}
                disabled={isValidateDisabled() || !isActionPermitted(permissions.invoiceValidate, user.permissions) || statusChangeLoading}
              >
                {ready && t('INVOICE_EDIT_FORM_READY_TO_EXPORT')}
              </Button>
            </div>
          </Tooltip>
        </>
      )}
      {(invoice.status === 501 && isToValidateStatusAllowed) && (
        <>
          <Tooltip
            title={!isActionPermitted(permissions.invoiceValidate, user.permissions)
              ? t('INVOICE_EDIT_FORM_VALIDATE_TOOLTIP_PERMISSION')
              : ''}
          >
            <div>
              <Button
                className="btn-reject"
                variant="outlined"
                onClick={() => handleAutoSaveInvoice(510)}
                disabled={isValidateDisabled() || !isActionPermitted(permissions.invoiceValidate, user.permissions) || statusChangeLoading}
              >
                {ready && t('INVOICE_EDIT_FORM_REJECT')}
              </Button>
            </div>
          </Tooltip>
          <Tooltip
            title={!isActionPermitted(permissions.invoiceValidate, user.permissions)
              ? t('INVOICE_EDIT_FORM_VALIDATE_TOOLTIP_PERMISSION')
              : ''}
          >
            <div>
              <Button
                className={classes.validateButton}
                variant="contained"
                color="secondary"
                onClick={() => handleAutoSaveInvoice(502)}
                disabled={isValidateDisabled() || !isActionPermitted(permissions.invoiceValidate, user.permissions) || statusChangeLoading}
              >
                {ready && t('INVOICE_EDIT_FORM_VALIDATE')}
              </Button>
            </div>
          </Tooltip>
        </>
      )}
      {(invoice.status === 502 || invoice.status === 503) && (
        <>
          {
            !user.isDocunecta && (
              <Tooltip
                title={!isActionPermitted(permissions.invoiceExport, user.permissions)
                  ? t('INVOICE_EXPORT_TOOLTIP_NO_PERMISSION')
                  : ''}
              >
                <div>
                  <Button
                    className="btn-tertiary"
                    variant="contained"
                    onClick={() => setSendEmailModalOpen(true)}
                    disabled={isValidateDisabled() || !isActionPermitted(permissions.invoiceExport, user.permissions)}
                  >
                    {ready && t('INVOICE_SEND_TO_EMAIL_BTN')}
                  </Button>
                </div>
              </Tooltip>
            )
          }
          <ButtonGroup variant="outlined" ref={dataFormatOptionsToExportRef} aria-label="split button">
            <Tooltip
              title={!isActionPermitted(permissions.invoiceExport, user.permissions)
                ? t('INVOICE_EXPORT_TOOLTIP_NO_PERMISSION')
                : ''}
            >
              <div>
                <Button
                  className={classes.ublButton}
                  variant="contained"
                  color="secondary"
                  onClick={handleDataformatOptionsToExportToggle}
                  disabled={isValidateDisabled() || !isActionPermitted(permissions.invoiceExport, user.permissions)}
                  endIcon={<ArrowDropDownIcon />}
                >
                  {ready && t('INVOICE_EXPORT_BTN')}
                </Button>
              </div>
            </Tooltip>
          </ButtonGroup>
          <Popper
            open={dataFormatOptionsToExportOpen}
            anchorEl={dataFormatOptionsToExportRef.current}
            role={undefined}
            transition
            style={{ zIndex: 111111 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleDataformatOptionsToExportClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {deliverFormats.map((option) => {
                        if (!option.exportViaApp) {
                          return null;
                        }

                        return (
                          <Tooltip
                            key={option.id}
                            title={(option.key === 'gstock' && invoice.exportedToGstock) ? t('GSTOCK_EXPORT_DISABLED_TOOLTIP') : ''}
                            placement="left"
                          >
                            <span>
                              <MenuItem
                                key={option.id}
                                onClick={() => { setDataFormatOptionsToExportOpen(false); handleDataFormatToExportClick(option); }}
                                className={classes.groupBtn}
                                disabled={option.key === 'gstock' && invoice.exportedToGstock}
                              >
                                {`${t('INVOICE_EDIT_FORM_EXPORT_TO')} ${option.label}`}
                              </MenuItem>
                            </span>
                          </Tooltip>
                        );
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
    </Box>
  );
};

InvoiceEditFormActionButtons.propTypes = {
  invoice: PropTypes.object,
  setExportDataModalOpen: PropTypes.func,
  setSendEmailModalOpen: PropTypes.func,
  isToValidateStatusAllowed: PropTypes.bool,
  touched: PropTypes.object,
  saveBtnRef: PropTypes.object,
  handleAutoSaveInvoice: PropTypes.func,
  statusChangeLoading: PropTypes.bool,
  deliverFormats: PropTypes.array,
  handleDataFormatToExportClick: PropTypes.func,
  isSubmitClicked: PropTypes.bool,
  details: PropTypes.object,
  isTwinfield: PropTypes.bool,
  vatLines: PropTypes.array,
  esLines: PropTypes.array,
  handleSubmit: PropTypes.func,
  renderedFields: PropTypes.array,
  getIsError: PropTypes.func,
};

export default InvoiceEditFormActionButtons;
