/* eslint-disable no-nested-ternary */
import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Button, Checkbox, IconButton, Typography, Grid, Tooltip, ButtonGroup, Popper, Grow, Paper, ClickAwayListener,
  MenuList, MenuItem, Box, Select, Avatar, Link, FormGroup, FormControlLabel
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  ArrowDropDown as ArrowDropDownIcon,
  GetApp as DownloadIcon
} from '@material-ui/icons';
import {
  appendContactSupport,
  axiosDeleteHeaders,
  axiosHeaders,
  axiosHeadersWithArrayBuffer,
  getInvoiceStatus,
  getInvoiceStatusForDropdown,
  getInvoiceStatusKey,
  getLocalisedErrorString,
  getPermissionByInvoiceStatus,
  i18nByStatus,
  permissions,
} from 'src/Shared/utils/helpers';
import clsx from 'clsx';
import { invoiceStatusCodesList, invoiceStatusesKeys } from 'src/config';
import { isStatusChangeValid } from 'src/Shared/utils/invoiceStateChangeValidations';
import Axios from 'axios';
import FileSaver from 'file-saver';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

const BulkOperations = (props) => {
  const { API } = useContext(ConfigContext);
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectedInvoices,
    selectedAllInvoices,
    selectedSomeInvoices,
    handleSelectAllInvoices,
    isActionPermitted,
    setExportDataModalOpen,
    setSendEmailModalOpen,
    isChangeValid,
    handleChangeBulkStatus,
    isToValidateStatusAllowed,
    limit,
    total,
    loading,
    setLoading,
    setSelectedInvoices,
    search,
    filter,
    time,
    startTime,
    endTime,
    suppliers,
    users,
    containsFailedToExport,
    containsIsDuplicate,
    containsPossibleDuplicate,
    failedToExport,
    isDuplicate,
    isPossibleDuplicate,
    handleDeleteDuplicatesModalOpen,
    handleChangeFailedToExport,
    handleChangeFilterDuplicates,
    handleChangeFilterPossibleDuplicates,
    companyFilterApplied,
    getInvoices,
  } = props;

  const statusMenuRef = useRef(null);
  const [statusMenuOpen, setStatusMenuOpen] = useState(false);
  const [bulkOwner, setBulkOwner] = useState('');

  const isSelectedAllSplitRequired = !selectedInvoices.some((i) => i.status !== 200);

  const isSelectedAllPossibleDuplicates = !selectedInvoices.some((i) => i.status !== 201);

  const handleStatusMenuToggle = () => {
    setStatusMenuOpen(!statusMenuOpen);
  };

  const handleStatusMenuClose = (event) => {
    if (statusMenuRef.current && statusMenuRef.current.contains(event?.target)) {
      return;
    }
    setStatusMenuOpen(false);
  };

  const handleBulkStatusChangeClick = (to, e) => {
    handleStatusMenuClose(e);
    handleChangeBulkStatus(to);
  };

  const handleClickOnExportBtn = () => {
    setExportDataModalOpen(true);
  };

  const isBulkActionChangeBtnDisabled = (status) => selectedInvoices.some((invoice) => (invoice.status !== status));

  const isExportButtonDisabled = () => {
    if (selectedInvoices.length === 0) {
      return true;
    }
    return selectedInvoices.some((invoice) => (invoice.status !== 502 && invoice.status !== 503));
  };

  const downloadInvoicesBulk = async () => {
    setLoading(true);
    const selectedAsstIDs = selectedInvoices.filter((i) => i.assistantID !== null).map((i) => i.assistantID);
    try {
      const response = await Axios.post(
        `${API.bulkDownload}`,
        { documents: selectedAsstIDs },
        axiosHeadersWithArrayBuffer(localStorage.getItem('PROCYS_accessToken'))
      );
      const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(blob, 'download.zip');
      setLoading(false);
    } catch {
      setLoading(false);
      enqueueSnackbar(t('BULK_DOWNLOAD_FAILED'), {
        variant: 'error',
        persist: true
      });
    }
  };

  const fetchAllAsstIdsAndSelect = async () => {
    setLoading(true);
    let thisSuppliers;
    if (Array.isArray(suppliers)) {
      thisSuppliers = suppliers.map((supplier) => supplier.creditorCode).join(',');
    } else if (suppliers) {
      thisSuppliers = suppliers;
    }
    try {
      let url = `${filter !== 'archived' ? API.getAllInvoiceIds : API.getAllArchivedInvoiceIds}/${user.companyID}?page=1${search !== ''
        ? `&search=${search}` : ''}${filter !== '' && filter !== 'archived' ? `&filter=${filter}` : ''}`;
      if (startTime && startTime !== '' && endTime && endTime !== '') {
        url = `${url}&startTime=${startTime}&endTime=${endTime}`;
      } else {
        url = `${url}${time && time !== '' ? `&time=${time}` : ''}`;
      }
      let response;
      if (filter !== 'archived') {
        response = await Axios.post(
          url,
          { suppliers: thisSuppliers },
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );
      } else {
        response = await Axios.get(
          url,
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );
      }
      if (response.data.success) {
        setSelectedInvoices(response.data.data);
      }
      setLoading(false);
    } catch {
      setLoading(false);
      enqueueSnackbar(t('BULK_SELECT_FAILED'), {
        variant: 'error',
        persist: true
      });
    }
  };

  const handleBulkOwnerChange = async (e) => {
    setBulkOwner(e.target.value);
    try {
      const body = {
        assignee: e.target.value,
        company: user.companyID,
        assistantID: selectedInvoices.map((i) => i.assistantID.toString())
      };

      const response = await Axios.post(
        API.bulkDocumentOwner,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        getInvoices();
        setSelectedInvoices([]);
        enqueueSnackbar(t('PROCYS_OWNER_CHANGE_SUCCESS'), {
          variant: 'success',
          persist: true
        });
      }
    } catch (error) {
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
      || appendContactSupport(window.config.support_email, t('PROCYS_OWNER_CHANGE_FAILED'), t), {
        variant: 'error',
        persist: true
      });
    }
  };

  const handleBulkDocumentSplit = async (splitType) => {
    setLoading(true);
    try {
      const body = {
        documentIDs: selectedInvoices.map((i) => i.id.toString()),
        splitType,
        pageCount: '1',
        pageRange: [],
      };

      const response = await Axios.post(
        API.bulkSplit,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      setLoading(false);
      if (response.data.success) {
        setSelectedInvoices([]);
        getInvoices();
        enqueueSnackbar(t('SPLIT_DOCUMENT_BULK_SUCCESS'), {
          variant: 'success',
          persist: true
        });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
      || appendContactSupport(window.config.support_email, t('SPLIT_DOCUMENT_FAILED'), t), {
        variant: 'error',
        persist: true
      });
    }
  };

  const handleBulkProcessDocument = async () => {
    setLoading(true);
    try {
      const body = {
        IDs: selectedInvoices.map((i) => parseInt(i.id, 10))
      };
      const response = await Axios.post(
        API.processNoneDuplicate,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setSelectedInvoices([]);
        getInvoices();
        setLoading(false);
        enqueueSnackbar(t('DOCUMENTS_SENT_TO_PROCESS_SUCCESS'), {
          variant: 'success',
          persist: true
        });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(t('DOCUMENTS_SENT_TO_PROCESS_FAILED'), {
        variant: 'error',
        persist: true
      });
    }
  };

  const handleBulkDelete = async () => {
    try {
      const body = {
        company: user.companyID,
        IDs: selectedInvoices.map((i) => i.id.toString())
      };

      const response = await Axios.delete(
        API.updateInvoice,
        axiosDeleteHeaders(localStorage.getItem('PROCYS_accessToken'), body)
      );
      if (response.data.success) {
        getInvoices();
        enqueueSnackbar(t('INVOICES_DELETE_SUCCESS'), {
          variant: 'success',
          persist: true
        });
        setSelectedInvoices([]);
      }
    } catch (error) {
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
      || appendContactSupport(window.config.support_email, t('INVOICES_DELETE_FAILED'), t), {
        variant: 'error',
        persist: true
      });
    }
  };

  const bulkActionChangeBtn = (status) => status !== 200 && status !== 201 && (
    <>
      <ButtonGroup variant="outlined" ref={statusMenuRef} aria-label="split button">
        <Tooltip title={isBulkActionChangeBtnDisabled(status)
          ? ready && t('DASHBOARD_INVOICES_BULK_INVALID_STATUS')
          : !isActionPermitted(getPermissionByInvoiceStatus(status), user.permissions)
            ? `INVOICE_STATUS_CHANGE_TOOLTIP_NO_PERMISSION_${i18nByStatus(status)}`
            : ''}
        >
          <div>
            <Button
              className={clsx({ [classes[getInvoiceStatusKey(status)]]: getInvoiceStatusKey(status) }, classes.ublButton)}
              variant="contained"
              onClick={handleStatusMenuToggle}
              disabled={!isActionPermitted(getPermissionByInvoiceStatus(status), user.permissions) || isBulkActionChangeBtnDisabled(status)}
              endIcon={<ArrowDropDownIcon />}
            >
              {ready && t(getInvoiceStatus(status))}
            </Button>
          </div>
        </Tooltip>
      </ButtonGroup>
      <Popper
        open={statusMenuOpen}
        anchorEl={statusMenuRef.current}
        role={undefined}
        transition
        style={{ zIndex: 111111, width: 150 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleStatusMenuClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {
                    invoiceStatusCodesList.map((s) => {
                      if (status !== s && isStatusChangeValid(status, s, isToValidateStatusAllowed)) {
                        return (
                          <MenuItem
                            key={s}
                            onClick={(e) => handleBulkStatusChangeClick(s, e)}
                            className={classes.groupBtn}
                          >
                            {ready && t(getInvoiceStatusForDropdown(s))}
                          </MenuItem>
                        );
                      }
                      return null;
                    })
                  }
                  {
                    ((status > 16 && status <= 100) || status === 600) && (
                      <MenuItem
                        key={900}
                        onClick={(e) => handleBulkStatusChangeClick(900, e)}
                        disabled={!isActionPermitted(permissions.invoiceDelete, user.permissions)}
                        className={classes.groupBtn}
                      >
                        {ready && t(getInvoiceStatusForDropdown(900))}
                      </MenuItem>
                    )
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );

  const bulkSplitActions = () => isSelectedAllSplitRequired && (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <Button
        variant="outlined"
        className={classes.dontSplitBtn}
        disabled={loading}
        onClick={() => handleBulkDocumentSplit('no')}
      >
        {t('INVOICE_SPLIT_REQUEST_DONT_SPLIT')}
      </Button>
      <Button
        variant="outlined"
        className={classes.dontSplitBtn}
        disabled={loading}
        onClick={() => handleBulkDocumentSplit('fixed')}
      >
        {t('INVOICE_SPLIT_REQUEST_SPLIT_PER_PAGE')}
      </Button>
    </Box>
  );

  const bulkPossibleDuplicatesActions = () => isSelectedAllPossibleDuplicates && (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <Button
        variant="outlined"
        className={classes.dontSplitBtn}
        disabled={loading}
        onClick={() => handleBulkProcessDocument()}
      >
        {t('INVOICE_DUPLICATE_NO_DUPLICATE')}
      </Button>
    </Box>
  );

  const renderBulkOwner = () => {
    if (!bulkOwner) {
      return (
        <Box display="flex" justifyContent="flex-start" alignItems="flex-end">
          <Typography className={classes.assignUserText}>
            {t('DASHBOARD_INVOICES_ASSIGN_USER')}
          </Typography>
        </Box>
      );
    }
    const owner = users.find((u) => u.email === bulkOwner);
    return (
      <Box
        display="flex"
        alignItems="center"
      >
        <Avatar
          alt={owner.firstname && owner.firstname[0]}
          className={classes.userImage}
          src={
            owner.avatar && owner.avatar.indexOf('data:image') >= 0
              ? owner.avatar : `${API.getProfileImage}${owner.avatar}`
          }
        />
        <Box ml={2}>
          <Link
            variant="subtitle2"
            color="textPrimary"
            component={RouterLink}
            underline="none"
            to="#"
            className={classes.usernameText}
            style={{ color: '#000000' }}
          >
            {`${owner.firstname} ${owner.lastname}`}
          </Link>
        </Box>
      </Box>
    );
  };

  return (
    <Grid>
      <div className={classes.actionSection}>
        <Checkbox
          disabled={loading}
          checked={selectedInvoices.length > 0 && (selectedAllInvoices || selectedInvoices.length === total)}
          indeterminate={selectedSomeInvoices}
          onChange={handleSelectAllInvoices}
          className={classes.checkbox}
          icon={<Grid className={classes.checkboxIcon} />}
        />
        {
          selectedInvoices.length === 0 && (
            <Typography className={classes.bulkText}>
              {ready && t('DASHBOARD_INVOICES_NOT_SELECT')}
            </Typography>
          )
        }
        {containsFailedToExport && filter === invoiceStatusesKeys.toexport && (
          <FormGroup>
            <FormControlLabel
              classes={{ label: classes.checkboxLabel }}
              control={(
                <Checkbox
                  checked={failedToExport}
                  className={classes.checkboxExportFailFilter}
                  onChange={handleChangeFailedToExport}
                />
                              )}
              label={ready && t('INVOICE_LIST_VIEW_EXPORT_FAIL_DOCUMENTS_FILTER')}
            />
          </FormGroup>
        )}
        {
        (containsIsDuplicate && (filter !== invoiceStatusesKeys.rejected
        && filter !== invoiceStatusesKeys.errored
        && filter !== invoiceStatusesKeys.archived)) && (
          <FormGroup>
            <FormControlLabel
              classes={{ label: classes.checkboxLabel }}
              control={(
                <Checkbox
                  checked={isDuplicate}
                  className={classes.checkboxExportFailFilter}
                  onChange={handleChangeFilterDuplicates}
                />
                              )}
              label={ready && t('INVOICE_LIST_VIEW_EXPORT_DULICATE_DOCUMENTS_FILTER')}
            />
          </FormGroup>
        )
        }
        {
        (containsPossibleDuplicate && (filter !== invoiceStatusesKeys.rejected
        && filter !== invoiceStatusesKeys.errored
        && filter !== invoiceStatusesKeys.archived
        && filter !== invoiceStatusesKeys.toreview
        && filter !== invoiceStatusesKeys.reviewed
        && filter !== invoiceStatusesKeys.toexport
        && filter !== invoiceStatusesKeys.exported)) && (
          <FormGroup>
            <FormControlLabel
              classes={{ label: classes.checkboxLabel }}
              control={(
                <Checkbox
                  checked={isPossibleDuplicate}
                  className={classes.checkboxExportFailFilter}
                  onChange={handleChangeFilterPossibleDuplicates}
                />
                              )}
              label={ready && t('INVOICE_LIST_POSSIBLE_DULICATE_DOCUMENTS_FILTER')}
            />
          </FormGroup>
        )
        }
        {
          selectedInvoices.length > 0 && (
            <Grid className={classes.actionSection}>
              {bulkActionChangeBtn(selectedInvoices[0].status)}
              {!companyFilterApplied && bulkSplitActions()}
              {bulkPossibleDuplicatesActions()}
              {!user.isDocunecta && selectedInvoices[0].status !== 200 && selectedInvoices[0].status !== 201 && (
                <Tooltip
                  title={!isActionPermitted(permissions.invoiceExport, user.permissions)
                    ? ready && t('INVOICE_EXPORT_TOOLTIP_NO_PERMISSION')
                    : isExportButtonDisabled()
                      ? ready && t('INVOICE_EXPORT_TOOLTIP_INVALID_STATUS')
                      : ''}
                >
                  <span>
                    <Button
                      className="btn-tertiary-sm"
                      variant="contained"
                      onClick={() => setSendEmailModalOpen(true)}
                      disabled={companyFilterApplied || isExportButtonDisabled() || !isActionPermitted(permissions.invoiceExport, user.permissions)}
                    >
                      {ready && t('INVOICE_SEND_TO_EMAIL_BTN')}
                    </Button>
                  </span>
                </Tooltip>
              )}
              {selectedInvoices[0].status !== 200 && selectedInvoices[0].status !== 201 && (
                <Tooltip
                  title={!isActionPermitted(permissions.invoiceExport, user.permissions)
                    ? ready && t('INVOICE_EXPORT_TOOLTIP_NO_PERMISSION')
                    : isExportButtonDisabled()
                      ? ready && t('INVOICE_EXPORT_TOOLTIP_INVALID_STATUS')
                      : ''}
                >
                  <span>
                    <Button
                      className={classes.ublButton}
                      variant="contained"
                      color="secondary"
                      onClick={handleClickOnExportBtn}
                      disabled={companyFilterApplied || isExportButtonDisabled() || !isActionPermitted(permissions.invoiceExport, user.permissions)}
                    >
                      {ready && t('INVOICE_EXPORT_BTN')}
                    </Button>
                  </span>
                </Tooltip>
              )}
              <Select
                disabled={companyFilterApplied || loading}
                onChange={handleBulkOwnerChange}
                className={bulkOwner ? classes.formInputSelected : classes.formInputSelect}
                value={bulkOwner}
                variant="outlined"
                displayEmpty
                renderValue={renderBulkOwner}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
              >
                {users.map((u) => (
                  <MenuItem key={u.email} value={u.email}>
                    <Box
                      display="flex"
                      alignItems="center"
                    >
                      <Avatar
                        alt={u.firstname && u.firstname[0]}
                        className={classes.userImage}
                        src={
                          u.avatar && u.avatar.indexOf('data:image') >= 0
                            ? u.avatar : `${API.getProfileImage}${u.avatar}`
                        }
                      />
                      <Box ml={2}>
                        <Link
                          variant="subtitle2"
                          color="textPrimary"
                          component={RouterLink}
                          underline="none"
                          to="#"
                          className={classes.usernameText}
                          style={{ color: '#000000' }}
                        >
                          {`${u.firstname} ${u.lastname}`}
                        </Link>
                      </Box>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
              <Tooltip
                title={ready && t(selectedInvoices.some((invoice) => (invoice.status === 200))
                  ? 'DASHBOARD_INVOICES_DOWNLOAD_DISABLED_TOOLTIP'
                  : 'DASHBOARD_INVOICES_DOWNLOAD_TOOLTIP')}
              >
                <span>
                  <IconButton
                    variant="text"
                    className={classes.bulkDownload}
                    onClick={downloadInvoicesBulk}
                    disabled={companyFilterApplied || selectedInvoices.some((invoice) => (invoice.status === 200 || invoice.status === 201)) || loading}
                  >
                    <DownloadIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip
                title={ready && t(isChangeValid(600) ? 'DASHBOARD_INVOICES_ARCHIEVE_TOOLTIP' : 'DASHBOARD_INVOICES_ARCHIEVE_TOOLTIP_DISABLED')}
              >
                <span>
                  <IconButton
                    variant="text"
                    className={classes.bulkArchieve}
                    disabled={selectedInvoices.length === 0 || loading}
                    onClick={isChangeValid(600) ? () => handleChangeBulkStatus(600) : () => {}}
                  >
                    <ArchiveIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip
                title={!isChangeValid(900) ? t('INVOICE_STATUS_CHANGE_DELETE_INVALID')
                  : !isActionPermitted(permissions.invoiceArchive, user.permissions)
                    ? ready && t('INVOICE_STATUS_CHANGE_TOOLTIP_NO_PERMISSION_DELETE')
                    : ready && t('INVOICE_STATUS_CHANGE_DELETE')}
              >
                <span>
                  <IconButton
                    variant="text"
                    className={classes.bulkDelete}
                    onClick={isChangeValid(900) ? () => handleBulkDelete() : () => {}}
                    disabled={companyFilterApplied || !isChangeValid(900) || !isActionPermitted(permissions.invoiceArchive, user.permissions) || loading}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
              {selectedInvoices.some((invoice) => invoice.isDuplicate) && (
                <Tooltip title={t('DELETE_DUPLICATES_TOOLTIP')}>
                  <span>
                    <IconButton
                      disabled={companyFilterApplied || loading}
                      variant="text"
                      className={classes.deleteDuplicates}
                      onClick={handleDeleteDuplicatesModalOpen}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </Grid>
          )
        }
      </div>
      <Grid className={classes.actionSection}>
        {
          selectedInvoices.length === limit && (
            <>
              <Typography className={classes.bulkText}>
                {t('DASHBOARD_INVOICES_SELECT_ALL_01', { length: selectedInvoices.length })}
              </Typography>
              <Typography className={classes.bulkTextLink} onClick={fetchAllAsstIdsAndSelect}>
                {t('DASHBOARD_INVOICES_SELECT_ALL_02', { total })}
              </Typography>
            </>
          )
        }
        {
          selectedInvoices.length > limit && selectedInvoices.length === total && (
            <Typography className={classes.bulkText}>
                {t('DASHBOARD_INVOICES_SELECT_ALL_03', { total })}
            </Typography>
          )
        }
      </Grid>
    </Grid>
  );
};

BulkOperations.propTypes = {
  selectedInvoices: PropTypes.array,
  selectedAllInvoices: PropTypes.bool,
  selectedSomeInvoices: PropTypes.bool,
  handleSelectAllInvoices: PropTypes.func,
  isActionPermitted: PropTypes.func,
  setExportDataModalOpen: PropTypes.func,
  setSendEmailModalOpen: PropTypes.func,
  isChangeValid: PropTypes.func,
  handleChangeBulkStatus: PropTypes.func,
  isToValidateStatusAllowed: PropTypes.bool,
  limit: PropTypes.number,
  total: PropTypes.number,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  setSelectedInvoices: PropTypes.func,
  search: PropTypes.string,
  filter: PropTypes.string,
  time: PropTypes.number,
  startTime: PropTypes.number,
  endTime: PropTypes.number,
  suppliers: PropTypes.array,
  users: PropTypes.array,
  containsFailedToExport: PropTypes.bool,
  containsIsDuplicate: PropTypes.bool,
  failedToExport: PropTypes.bool,
  isDuplicate: PropTypes.bool,
  isPossibleDuplicate: PropTypes.bool,
  containsPossibleDuplicate: PropTypes.bool,
  handleDeleteDuplicatesModalOpen: PropTypes.func,
  handleChangeFailedToExport: PropTypes.func,
  handleChangeFilterDuplicates: PropTypes.func,
  handleChangeFilterPossibleDuplicates: PropTypes.func,
  companyFilterApplied: PropTypes.bool,
  getInvoices: PropTypes.func,
};

export default BulkOperations;
