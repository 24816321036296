import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  linesContainer: {
    padding: '16px 32px 32px',
    backgroundColor: '#ffffff',
    boxShadow: '0px -2px 4px rgb(0 0 0 / 8%)',
  },
  lineHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 0px 0px'
  },
  linesInExtractDataCheckBoxContainer: {
    padding: '0px 0px 10px'
  },
  lineTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  lineTitle: {
    fontSize: 16,
    color: '#334D6E',
    padding: '0px',
    marginRight: '32px'
  },
  lineNumber: {
    fontSize: 14,
    color: 'rgba(51, 77, 110, 0.5)',
  },
  checkBoxLabel: {
    fontSize: 14,
    color: '#334D6E'
  },
  checkBoxLabelSmall: {
    fontSize: 12,
    color: '#334D6E'
  },
  checkBox: {
    color: 'rgba(0, 0, 0, 0.26)',
    fontWeight: 500
  },
  action: {
    fontSize: 14,
    fontWeight: 600,
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
  },
  actionIcon: {
    color: 'rgba(255, 255, 255, 0.8)',
    marginRight: theme.spacing(1)
  },
  actionBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  editIcon: {
    fontSize: 16,
    color: '#3E8AFF'
  },
  deleteIcon: {
    fontSize: 16,
    color: '#F7685B',
    marginLeft: 16
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'scroll',
    width: 980,
  },
  selectStyle: {
    color: '#334D6E',
    fontSize: 13,
    lineHeight: '20px',
  },
  selectInputStyle: {
    padding: '0px 32px 0px 0px !important',
    border: '0.5px rgba(0, 0, 0, 0.26) solid',
  },
  inputStyle: {
    backgroundColor: '#fff',
    border: '0.5px rgba(0, 0, 0, 0.26) solid',
    borderRadius: 2,
  },
  inputRoot: {
    height: 36,
  },
  errorState: {
    backgroundColor: '#facbc8',
    border: '0.5px #fc1100 solid',
    borderRadius: 2,
  },
  errorSelectStyle: {
    color: '#334D6E',
    fontSize: 13,
    lineHeight: '20px',
    backgroundColor: '#facbc8',
    border: '0.5px #fc1100 solid',
    borderRadius: 2,
  },
  selectFieldHighlighted: {
    color: '#334D6E',
    fontSize: 13,
    lineHeight: '20px',
    backgroundColor: '#d7e7fc',
    border: '0.5px #334D6E solid',
    borderRadius: 2,
  },
  textFieldHighlited: {
    backgroundColor: '#d7e7fc',
    border: '0.5px #334D6E solid',
    borderRadius: 2,
  },
  btnIcon: {
    height: 20,
    width: 20,
    marginRight: 4,
    color: '#bebebe',
  },
  addIcon: {
    height: 20,
    width: 20,
    marginRight: 4,
    color: '#3E8AFF',
  },
  iconButton: {
    justifyContent: 'center',
  },
  tablesContainer: {
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '340px',
    overflowY: 'scroll',
  },
  tableHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  table1: {
    width: '8%',
    overflowX: 'scroll',
  },
  table2: {
    width: '84%',
    overflowX: 'scroll',
  },
  table3: {
    width: '8%',
    overflowX: 'scroll',
  },
  table2Row: {
    display: 'flex',
    width: '100%',
    borderCollapse: 'separate',
  },
  draggableIconHeader: {
    width: '32px !important',
    height: '61.5px !important'
  },
  draggableIcon: {
    width: '32px !important',
    height: '56.5px',
  },
  reorderIcon: {
    fontSize: 28,
    color: '#000000',
    padding: '6px 0px 0px 0px !important'
  },
  columnNumber: {
    width: '32px !important',
    height: '61.5px !important',
    padding: '2px !important',
    paddingRight: '2px !important',
  },
  colHeight: {
    width: '32px !important',
    height: '56.5px',
    padding: '2px !important',
    paddingRight: '2px !important',
  },
  headerHeight: {
    height: '61.5px !important'
  },
  table2CellLong: {
    height: '56.5px',
    minWidth: '300px',
  },
  table2CellShort: {
    height: '56.5px',
    minWidth: '172px',
  },
  table2CellVeryShort: {
    height: '56.5px',
    minWidth: '100px',
  },
  table2Cell4fieldsLong: {
    height: '56.5px',
    minWidth: '400px',
  },
  table2Cell4fieldsShort: {
    height: '56.5px',
    minWidth: '260px',
  },
  table2Cell4fieldsVeryShort: {
    height: '56.5px',
    minWidth: '120px',
  },
  table2Cell5fieldsLong: {
    height: '56.5px',
    minWidth: '400px',
  },
  table2Cell5fieldsShort: {
    height: '56.5px',
    minWidth: '200px',
  },
  table2Cell6fieldsLong: {
    height: '56.5px',
    minWidth: '320px',
  },
  table2Cell6fieldsShort: {
    height: '56.5px',
    minWidth: '180px',
  },
  table2Cell7fieldsLong: {
    height: '56.5px',
    minWidth: '300px',
  },
  table2Cell7fieldsShort: {
    height: '56.5px',
    minWidth: '140px',
  },
  textFieldHighlighted: {
    backgroundColor: '#d7e7fc'
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  reprocessingBtn: {
    color: '#334D6E',
    fontSize: 12,
    textTransform: 'none',
    border: '1px solid #334D6E',
    height: 32,
  }
}));

export default useStyles;
