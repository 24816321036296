import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Avatar, Button, ButtonGroup, Chip, CircularProgress, ClickAwayListener, Grid, Grow, Menu, MenuItem, MenuList,
  Paper, Popper, Tooltip, Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { isStatusChangeAble, isStatusChangeValid } from 'src/Shared/utils/invoiceStateChangeValidations';

import {
  invoiceStatusCode, invoiceStatusCodesList,
} from 'src/config';
import {
  appendContactSupport, axiosHeaders, getInvoiceStatus, getInvoiceStatusForDropdown, getInvoiceStatusKey,
  getLocalisedErrorString, getPermissionByInvoiceStatus, i18nByStatus, isActionPermitted, permissions
} from 'src/Shared/utils/helpers';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import ConfigContext from 'src/Contexts';
import useStyles from './style';

const DocumentHeader = (props) => {
  const { BRAND_NAME, API } = useContext(ConfigContext);
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const {
    invoice,
    statusMenuOpen,
    users,
    statusChangeLoading,
    isToValidateStatusAllowed,
    gotoInvoiceList,
    statusMenuRef,
    handleStatusMenuToggle,
    handleStatusMenuClose,
    handleStatusChange,
    handleUserMenuOpen,
    usersMenuOpen,
    usersMenuAnchor,
    handleUserMenuClose,
    getInvoice,
  } = props;

  const filteredUsers = users && users.length > 0 ? users.filter(
    (u) => isStatusChangeAble(invoice.status, u?.userRole) && invoice.owner !== u.email
  ) : [];

  const handleSelectOwner = async (owner, id) => {
    handleUserMenuClose();

    try {
      const body = {
        assistantID: parseInt(id, 10),
        owner: owner.email
      };

      const response = await Axios.post(
        API.documentOwner,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        getInvoice();
        enqueueSnackbar(t('PROCYS_OWNER_CHANGE_SUCCESS'), {
          variant: 'success',
          persist: true
        });
      }
    } catch (error) {
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
      || appendContactSupport(window.config.support_email, t('PROCYS_OWNER_CHANGE_FAILED'), t), {
        variant: 'error',
        persist: true
      });
    }
  };

  const formatExportFailReason = (reason) => {
    const str = `${t('FAILED_TO_EXPORT_DOCUMENT')}:`;
    if (reason.includes('__MESSAGE__')) {
      const arr = reason.split('__MESSAGE__');
      if (arr.length === 2) {
        return appendContactSupport(window.config.support_email, `${str} ${t(arr[0])}. ${arr[1]}`, t);
      }
      if (arr.length === 3) {
        return appendContactSupport(window.config.support_email, `${str} ${t(arr[0])}. ${arr[1]}, ${arr[2]}`, t);
      }
    }
    if (reason.includes('__BR__')) {
      const arr = reason.split('__BR__');
      if (arr.length === 2) {
        return appendContactSupport(window.config.support_email, `${str} ${t(arr[0])}. ${t(arr[1])}`, t);
      }
      if (arr.length === 3) {
        return appendContactSupport(window.config.support_email, `${str} ${t(arr[0])}. ${t(arr[1])}, ${t(arr[2])}`, t);
      }
      if (arr.length === 4) {
        if (arr[2].includes('LINE')) {
          return appendContactSupport(
            window.config.support_email,
            `${str} ${t(arr[0])}. ${t(arr[1])}, ${t(arr[2])} - ${t('DOCUMENT_EXPORT_ERROR_LINE_NO')} ${arr[3]}`,
            t
          );
        }
        return appendContactSupport(window.config.support_email, `${str} ${t(arr[0])}. ${t(arr[1])}, ${t(arr[2])} ${t(arr[3])}`, t);
      }
    }
    return appendContactSupport(window.config.support_email, str, t);
  };

  const renderUserMenu = () => {
    if (filteredUsers && filteredUsers.length === 0) {
      return null;
    }

    return (
      <Menu
        id="user-menu"
        anchorEl={usersMenuAnchor}
        keepMounted
        open={usersMenuOpen}
        onClose={handleUserMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: classes.paper,
          list: classes.menuPadding
        }}
      >
        {
          filteredUsers.map((u, i) => (
            <MenuItem
              key={u.email}
              onClick={() => handleSelectOwner(u, invoice.assistantID)}
              className={
                i === 0
                  ? classes.menuActionContainerTop
                  : classes.menuActionContainer
              }
            >
              {`${u.firstname} ${u.lastname} | ${u.email}`}
            </MenuItem>
          ))
        }
      </Menu>
    );
  };

  return (
    <Grid className={classes.titleContainer}>
      <Grid>
        <Button className={classes.backBtn} onClick={gotoInvoiceList}>
          <ArrowBackIcon className={classes.icon} />
          {ready && t('INVOICE_EDIT_BACK_TO_OVERVIEW')}
        </Button>
      </Grid>
      <Grid className={classes.assistantIDBox}>
        <Typography className={classes.assistantID}>{t('INVOICE_EDIT_PROCYS_NUMBER', { brand: BRAND_NAME, assistantID: invoice.assistantID })}</Typography>
        {
          !user.customisations.includes('dontIdentifyDuplicateDoc') && invoice.isDuplicate && (
            <Tooltip
              title={invoice?.supplier ? t('INVOICE_DUPLICATE_TOOLTIP_WITH_SUPPLIER') : t('INVOICE_DUPLICATE_TOOLTIP')}
            >
              <span className={classes.duplicate}>{t('INVOICE_DUPLICATE')}</span>
            </Tooltip>
          )
        }
        {
          invoice.errorReason && invoice.errorReason > 0 && (
            <Tooltip
              title={t(`INVOICE_ERROR_STATUS_${invoice.errorReason}`)}
            >
              <span className={classes.errorReason}>!</span>
            </Tooltip>
          )
        }
      </Grid>
      <Grid className={classes.titleBox}>
        <Typography className={classes.title}>
          {ready && `${t('INVOICE_EDIT_INVOICE')} ${t('INVOICE_EDIT_INVOICE_NO')}: ${invoice.documentNumber}`}
        </Typography>
        {(invoice.status === invoiceStatusCode.toexport && invoice.exportFailReason) && <span className={classes.exportFailed}>!</span>}
      </Grid>
      {(invoice.status === invoiceStatusCode.toexport && invoice.exportFailReason) && (
        <Grid className={classes.exportFailedReasonContainer}>
          <Typography className={classes.exportFailedReason}>
            {formatExportFailReason(invoice.exportFailReason)}
          </Typography>
        </Grid>
      )}
      <Grid className={classes.infoBox}>
        <Typography className={classes.subtitle} component="span">
          {ready && `${t('INVOICE_EDIT_UPLOADED')} ${moment.unix(invoice.appUploadedDate).format('DD/MM/YYYY')} ${t('INVOICE_EDIT_UPLOADED_BY')}`}
          <Chip
            size="small"
            avatar={<Avatar alt="" src={invoice.appUserImage} />}
            label={invoice.appUsername}
            classes={{
              root: classes.userChip,
              avatar: classes.chipAvatar,
              label: classes.chipLabel
            }}
          />
        </Typography>
        {
          statusChangeLoading
            ? (
              <Grid className={classes.action}>
                <CircularProgress size={20} />
              </Grid>
            ) : (
              <>
                <ButtonGroup variant="outlined" ref={statusMenuRef} aria-label="split button">
                  <Tooltip title={!isActionPermitted(getPermissionByInvoiceStatus(invoice.status), user.permissions)
                    ? `INVOICE_STATUS_CHANGE_TOOLTIP_NO_PERMISSION_${i18nByStatus(invoice.status)}`
                    : ''}
                  >
                    <div>
                      <Button
                        className={clsx({ [classes[getInvoiceStatusKey(invoice.status)]]: getInvoiceStatusKey(invoice.status) }, classes.ublButton)}
                        variant="contained"
                        onClick={handleStatusMenuToggle}
                        disabled={!isActionPermitted(getPermissionByInvoiceStatus(invoice.status), user.permissions)}
                        endIcon={<ArrowDropDownIcon />}
                      >
                        {ready && t(getInvoiceStatus(invoice.status))}
                      </Button>
                    </div>
                  </Tooltip>
                </ButtonGroup>
                <Popper
                  open={statusMenuOpen}
                  anchorEl={statusMenuRef.current}
                  role={undefined}
                  transition
                  style={{ zIndex: 111111, width: 150 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleStatusMenuClose}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {
                              invoiceStatusCodesList.map((s) => {
                                if (
                                  getInvoiceStatus(invoice.status) !== getInvoiceStatus(s)
                                  && isStatusChangeValid(invoice.status, s, isToValidateStatusAllowed)
                                ) {
                                  return (
                                    <MenuItem
                                      key={s}
                                      onClick={(e) => handleStatusChange(s, invoice.assistantID, e)}
                                      className={classes.groupBtn}
                                    >
                                      {ready && t(getInvoiceStatusForDropdown(s))}
                                    </MenuItem>
                                  );
                                }
                                return null;
                              })
                            }
                            {
                              ((invoice.status > 16 && invoice.status <= 100) || invoice.status === 600 || invoice.status === 510) && (
                              <MenuItem
                                key={900}
                                onClick={(e) => handleStatusChange(900, invoice.assistantID, invoice.id, e)}
                                disabled={!isActionPermitted(permissions.invoiceDelete, user.permissions)}
                                className={classes.groupBtn}
                              >
                                {ready && t(getInvoiceStatusForDropdown(900))}
                              </MenuItem>
                              )
                            }
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )
        }
      </Grid>
      <Grid className={classes.infoBox}>
        <Typography className={classes.subtitleOwnedInfo} component="span">
          <Grid className={classes.subtitleOwned}>
            {ready && t('INVOICE_EDIT_OWNED_BY')}
          </Grid>
          <Tooltip title={ready ? t('INVOICE_EDIT_OWNER_NO_PERMISSION') : ''} disableHoverListener={filteredUsers && filteredUsers.length > 0}>
            <Grid>
              <Chip
                size="small"
                avatar={<Avatar alt="" src={invoice.ownerAvatar} />}
                label={(
                  <Typography className={classes.subtitleChip} component="span">
                    {
                      invoice.ownerName && invoice.ownerName !== ' '
                        ? invoice.ownerName
                        : t('INVOICE_EDIT_UNASSIGNED')
                    }
                    <ChevronRightIcon className={classes.moreRightIcon} />
                  </Typography>
                )}
                classes={{
                  root: classes.userChip,
                  avatar: classes.chipAvatar,
                  label: classes.chipLabel
                }}
                onClick={handleUserMenuOpen}
              />
            </Grid>
          </Tooltip>
          {renderUserMenu(filteredUsers)}
        </Typography>
      </Grid>
    </Grid>
  );
};

DocumentHeader.propTypes = {
  invoice: PropTypes.object.isRequired,
  statusMenuOpen: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  statusChangeLoading: PropTypes.bool.isRequired,
  isToValidateStatusAllowed: PropTypes.bool.isRequired,
  gotoInvoiceList: PropTypes.func.isRequired,
  statusMenuRef: PropTypes.object.isRequired,
  handleStatusMenuToggle: PropTypes.func.isRequired,
  handleStatusMenuClose: PropTypes.func.isRequired,
  handleStatusChange: PropTypes.func.isRequired,
  handleUserMenuOpen: PropTypes.func.isRequired,
  usersMenuOpen: PropTypes.bool.isRequired,
  usersMenuAnchor: PropTypes.object,
  handleUserMenuClose: PropTypes.func.isRequired,
  getInvoice: PropTypes.func.isRequired,
};

export default DocumentHeader;
